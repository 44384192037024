import { IReqPortalStatus } from 'src/@types/portal-status';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/idash/portal-status';

const portalStatusApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: ['PortalStatus'],
});

const _apiWithTaggedEndpoint = portalStatusApi.injectEndpoints({
  endpoints: (build) => ({
    updatePortalStatus: build.mutation<any, IReqPortalStatus & { id: string }>({
      query: (data) => ({
        url: `${prefix}/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['PortalStatus'],
    }),
  }),
});

export const { useUpdatePortalStatusMutation } = _apiWithTaggedEndpoint;
