import * as baseKemedis from './base-kemedis.api';
import * as mainDashboard from './main-dashboard-api';
import * as healthcareProvider from './healthcare-provider.api';
import * as practitioner from './practitioner-api';
import * as location from './location-api';
import * as kfaBrand from './kfa-brand.api';
import * as medicine from './medicine.api';
import * as medicalDevice from './medical-device.api';
import * as providerProcedure from './provider-procedure.api';
import * as guarantor from './guarantor.api';
import * as healtcareReport from './healthcare-reports.api';
import * as portalStatus from './portal-status.api';
import * as activeStatus from './active-status.api';
import * as userReport from './user-repots.api';
import * as activatedUser from './activated-user.api';

export default {
  baseKemedis,
  mainDashboard,
  healthcareProvider,
  practitioner,
  location,
  kfaBrand,
  medicine,
  medicalDevice,
  providerProcedure,
  guarantor,
  healtcareReport,
  portalStatus,
  activeStatus,
  userReport,
  activatedUser,
};
