import { IReqActiveStatus } from 'src/@types/active-status';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/idash/active-status';

const activeStatusApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: ['ActiveStatus'],
});

const _apiWithTaggedEndpoint = activeStatusApi.injectEndpoints({
  endpoints: (build) => ({
    updateActiveStatus: build.mutation<any, IReqActiveStatus & { id: string }>({
      query: (data) => ({
        url: `${prefix}/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['ActiveStatus'],
    }),
  }),
});

export const { useUpdateActiveStatusMutation } = _apiWithTaggedEndpoint;
