import { IMainDashboard, IMainDashboardParams, INewMainDashboard } from 'src/@types/main-dashboard';
import { getProviderId } from 'src/utils/local-storage';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/kresna/api/kemedis/dashboard/main';

const mainDashboardApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: [`${prefix}`, 'MainDashboard'],
});

const _apiWithTaggedEndpoint = mainDashboardApi.injectEndpoints({
  endpoints: (build) => ({
    getMainDashboard: build.query<INewMainDashboard, IMainDashboardParams>({
      query: (mainDashboardParams) => ({
        url: `${prefix}/${mainDashboardParams.providerId}/${mainDashboardParams.periodUnitCode}`,
        method: 'GET',
      }),
      providesTags: ['/services/kresna/api/kemedis/dashboard/main'],
    }),
  }),
});

export const { useGetMainDashboardQuery } = _apiWithTaggedEndpoint;
