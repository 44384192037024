import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import {
  IMedicalDevice,
  IMedicalDevicePrepare,
  IReqMedicalDevice,
} from 'src/@types/medical-device';
import { getProviderId } from 'src/utils/local-storage';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/medical-devices';

const medicalDeviceApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: ['MedicalDevice'],
});

const _apiWithTaggedEndpoint = medicalDeviceApi.injectEndpoints({
  endpoints: (build) => ({
    getMedicalDevices: build.query<
      IPagination<IMedicalDevice>,
      Partial<IReqGlobalRsql> & { providerId: string }
    >({
      query: (reqRsql) => ({
        url: `${prefix}/providers/${reqRsql.providerId}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
      providesTags: ['MedicalDevice'],
    }),
    getMedicalDeviceById: build.query<IMedicalDevice, string>({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'GET',
      }),
    }),
    getMedicalDevicePrepare: build.query<IMedicalDevicePrepare, unknown>({
      query: () => ({
        url: `${prefix}/prepare/edit/${getProviderId()}`,
        method: 'GET',
      }),
      providesTags: ['MedicalDevice'],
    }),
    createMedicalDevice: build.mutation<IMedicalDevice, IReqMedicalDevice>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['MedicalDevice'],
    }),
    updateMedicalDevice: build.mutation<IMedicalDevice, IReqMedicalDevice>({
      query: (data) => ({
        url: `${prefix}/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['MedicalDevice'],
    }),
  }),
});

export const {
  useGetMedicalDevicesQuery,
  useGetMedicalDeviceByIdQuery,
  useGetMedicalDevicePrepareQuery,
  useCreateMedicalDeviceMutation,
  useUpdateMedicalDeviceMutation,
} = _apiWithTaggedEndpoint;
