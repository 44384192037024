import { ILabelValue, IPagination, IReqGlobalRsql } from 'src/@types/base';
import { normalizeParams } from 'src/utils/convertFilterString';
import { IHealthcareProvider } from 'src/@types/healthcare-provider';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/healthcare-provider';

const healthcareProviderApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: ['Administrative'],
});

const _apiWithTaggedEndpoint = healthcareProviderApi.injectEndpoints({
  endpoints: (build) => ({
    getHelathcareProviderLabelValues: build.query<ILabelValue[], unknown>({
      query: () => ({
        url: `${prefix}/labelvalues`,
        method: 'GET',
      }),
      providesTags: ['Administrative'],
    }),
  }),
});

export const { useGetHelathcareProviderLabelValuesQuery } = _apiWithTaggedEndpoint;
