import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import { getProviderId } from 'src/utils/local-storage';
import {
  IPractitioner,
  IPractitionerPrepare,
  IReqIhsPractitionerCreate,
  IReqIhsPractitionerUpdate,
} from 'src/@types/practitioner';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/practitioners';

const practitionerApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: [`${prefix}`, 'Practitioners'],
});

const _apiWithTaggedEndpoint = practitionerApi.injectEndpoints({
  endpoints: (build) => ({
    getPractitioners: build.query<
      IPagination<IPractitioner>,
      Partial<IReqGlobalRsql> & { providerId: string }
    >({
      query: (reqRsql) => ({
        url: `${prefix}/${reqRsql.providerId}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
      providesTags: ['/services/arjuna/api/kemedis/practitioners'],
    }),
    getPractitionerById: build.query<IPractitioner, string>({
      query: (id) => ({
        url: `${prefix}/id/${id}`,
        method: 'GET',
      }),
      providesTags: ['/services/arjuna/api/kemedis/practitioners'],
    }),
    getPractitionerPrepare: build.query<IPractitionerPrepare, unknown>({
      query: () => ({
        url: `${prefix}/prepare/${getProviderId()}`,
        method: 'GET',
      }),
      providesTags: ['/services/arjuna/api/kemedis/practitioners'],
    }),
    createPractitioner: build.mutation<IPractitioner, IReqIhsPractitionerCreate>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['/services/arjuna/api/kemedis/practitioners', 'Practitioners'],
    }),
    updatePractitioner: build.mutation<IPractitioner, IReqIhsPractitionerUpdate>({
      query: (data) => ({
        url: `${prefix}/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['/services/arjuna/api/kemedis/practitioners', 'Practitioners'],
    }),
  }),
});

export const {
  useGetPractitionersQuery,
  useGetPractitionerByIdQuery,
  useGetPractitionerPrepareQuery,
  useCreatePractitionerMutation,
  useUpdatePractitionerMutation,
} = _apiWithTaggedEndpoint;
