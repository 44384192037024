import { IPagination, IPaginationPage, IReqGlobalRsql } from 'src/@types/base';
import { normalizeParams } from 'src/utils/convertFilterString';
import { IUserReports } from 'src/@types/user-reports';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/kresna/api/kemedis/idash/user-reports';

const userReportApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: ['userReport'],
});

const _apiWithTaggedEndpoint = userReportApi.injectEndpoints({
  endpoints: (build) => ({
    getUserReport: build.query<IPaginationPage<IUserReports>, Partial<IReqGlobalRsql>>({
      query: (reqRsql) => ({
        url: `${prefix}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
    }),
  }),
});

export const { useGetUserReportQuery } = _apiWithTaggedEndpoint;
