import { IReqActivatedUser } from 'src/@types/activated-user';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/api/activated-user';

const activatedUserApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: ['ActivatedUser'],
});

const _apiWithTaggedEndpoint = activatedUserApi.injectEndpoints({
  endpoints: (build) => ({
    updateActivatedUser: build.mutation<any, IReqActivatedUser>({
      query: (data) => ({
        url: `${prefix}/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['ActivatedUser'],
    }),
  }),
});

export const { useUpdateActivatedUserMutation } = _apiWithTaggedEndpoint;
