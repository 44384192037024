import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import {
  IGuarantor,
  IPrepareGuarantor,
  IReqGuarantorCreate,
  IReqGuarantorUpdate,
} from 'src/@types/guarantor';
import { getProviderId } from 'src/utils/local-storage';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/guarantors';

const guarantorApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getGuarantors: build.query<
      IPagination<IGuarantor>,
      Partial<IReqGlobalRsql> & { providerId: string }
    >({
      query: (reqRsql) => ({
        url: `${prefix}/providers/${reqRsql.providerId}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
      // providesTags: ['/services/arjuna/api/kemedis/guarantors'],
    }),
    getGuarantorById: build.query<IGuarantor, string>({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'GET',
      }),
    }),
    getPrepareGuarantor: build.query<IPrepareGuarantor, unknown>({
      query: () => ({
        url: `${prefix}/prepare/edit/${getProviderId()}`,
        method: 'GET',
      }),
    }),
    createGuarantor: build.mutation<IGuarantor, IReqGuarantorCreate>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
      // invalidatesTags: ['/services/arjuna/api/kemedis/guarantors'],
    }),
    updateGuarantor: build.mutation<IGuarantor, IReqGuarantorUpdate>({
      query: (data) => ({
        url: `${prefix}/${data.id}`,
        method: 'PUT',
        data,
      }),
      // invalidatesTags: ['/services/arjuna/api/kemedis/guarantors'],
    }),
  }),
});

guarantorApi.enhanceEndpoints({
  addTagTypes: ['Guarantor'],
  endpoints: {
    getGuarantors: {
      providesTags: (result) =>
        result?.content
          ? [
              ...result.content.map(({ id }) => ({ type: 'Guarantor' as const, id })),
              { type: 'Guarantor', id: 'LIST' },
            ]
          : [{ type: 'Guarantor', id: 'LIST' }],
    },
    getGuarantorById: {
      providesTags: (result) => [{ type: 'Guarantor', id: result?.id }],
    },
    getPrepareGuarantor: {
      providesTags: [{ type: 'Guarantor', id: 'PREPARE' }],
    },
    createGuarantor: {
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'Guarantor', id: result.id },
              { type: 'Guarantor', id: 'LIST' },
            ]
          : [{ type: 'Guarantor', id: 'LIST' }],
    },
    updateGuarantor: {
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'Guarantor', id: result.id },
              { type: 'Guarantor', id: 'LIST' },
            ]
          : [{ type: 'Guarantor', id: 'LIST' }],
    },
  },
});

export const {
  useGetGuarantorsQuery,
  useGetGuarantorByIdQuery,
  useGetPrepareGuarantorQuery,
  useCreateGuarantorMutation,
  useUpdateGuarantorMutation,
} = guarantorApi;
