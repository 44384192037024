// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [{ title: 'Dashboard', path: PATH_DASHBOARD.app, icon: ICONS.dashboard }],
  },

  // providers
  {
    subheader: '',
    items: [
      {
        title: 'Daftar Fasyankes',
        path: PATH_DASHBOARD.providers.root,
        icon: ICONS.user,
        roles: ['master'],
      },
    ],
  },
  // end providers

  // users
  {
    subheader: '',
    items: [
      {
        title: 'Daftar Pengguna',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.user,
        roles: ['master'],
      },
    ],
  },
  // end users

  // master
  {
    subheader: '',
    items: [
      {
        title: 'Master',
        path: PATH_DASHBOARD.master.root,
        icon: ICONS.analytics,
        roles: ['master'],
        children: [
          {
            title: 'Lokasi',
            path: PATH_DASHBOARD.master.location.root,
            roles: ['master-ihs-location'],
          },
          {
            title: 'Tenaga Medis',
            path: PATH_DASHBOARD.master.practitioner.root,
            roles: ['master-ihs-practitioner'],
          },
          {
            title: 'depo farmasi',
            roles: ['master-farmasi'],
            path: PATH_DASHBOARD.master.pharmacy.root,
            children: [
              {
                title: 'obat dan alkes',
                path: PATH_DASHBOARD.master.pharmacy.medicineInput.root,
                roles: ['master-farmasi-medicine'],
              },
            ],
          },
          {
            title: 'produk dan layanan',
            roles: ['master-product-service'],
            path: PATH_DASHBOARD.master.productAndService.root,
            children: [
              {
                title: 'data produk',
                path: PATH_DASHBOARD.master.productAndService.product.root,
                roles: ['master-product-service-product'],
              },
            ],
          },
          {
            title: 'asuransi dan data perusahaan',
            path: PATH_DASHBOARD.master.guarantor.root,
            roles: ['master-guarantor'],
          },
        ],
      },
      // { title: 'Two', path: PATH_DASHBOARD.two, icon: ICONS.ecommerce },
      // { title: 'Three', path: PATH_DASHBOARD.three, icon: ICONS.analytics },
    ],
  },
  // end master

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: PATH_DASHBOARD.user.four },
  //         { title: 'Five', path: PATH_DASHBOARD.user.five },
  //         { title: 'Six', path: PATH_DASHBOARD.user.six },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
