import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  LoginPage,
  DashboardPage,
  MasterLocationPage,
  MasterPractitionerPage,
  MasterPharmacyPage,
  MasterProductServicePage,
  MasterGuarantorPage,
  ProviderListPage,
  UserListPage,
} from './elements';
import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_DASHBOARD.app} replace />, index: true },
        { path: 'app', element: <DashboardPage /> },
        {
          path: 'master',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.master.root} replace />, index: true },
            { path: 'location', element: <MasterLocationPage /> },
            { path: 'practitioner', element: <MasterPractitionerPage /> },
            {
              path: 'pharmacy',
              children: [
                {
                  element: <Navigate to="/dashboard/master/pharmacy/medicine-input" replace />,
                  index: true,
                },
                // medicine input
                {
                  path: 'medicine-input',
                  element: <MasterPharmacyPage />,
                },
              ],
            },
            {
              path: 'product-and-service',
              children: [
                {
                  element: (
                    <Navigate to={PATH_DASHBOARD.master.productAndService.product.root} replace />
                  ),
                  index: true,
                },
                {
                  path: 'product',
                  element: <MasterProductServicePage />,
                },
              ],
            },
            {
              path: 'guarantor',
              element: <MasterGuarantorPage />,
            },
          ],
        },
        {
          path: 'providers',
          element: <ProviderListPage />,
          // children: [
          //   { element: <Navigate to={PATH_DASHBOARD.providers.root} replace />, index: true },
          //   { path: 'list', element: <ProviderListPage /> },
          // ],
        },
        {
          path: 'users',
          element: <UserListPage />,
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
