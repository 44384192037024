import { getProviderId } from 'src/utils/local-storage';
import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import { IKfaBrand, IReqKfaBrandCreate, IReqKfaBrandUpdate } from 'src/@types/kfa-brand';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/kfa-brands';

const kfaBrandApi = baseKemedisApi.injectEndpoints({
  endpoints: (build) => ({
    getKfaBrands: build.query<
      IPagination<IKfaBrand>,
      Partial<IReqGlobalRsql> & { providerId: string }
    >({
      query: (reqRsql) => ({
        url: `${prefix}/providers/${reqRsql.providerId}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
    }),
    getKfaBrandById: build.query<IKfaBrand, string>({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'GET',
      }),
    }),
    createKfaBrand: build.mutation<IKfaBrand, IReqKfaBrandCreate>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
    }),
    updateKfaBrand: build.mutation<IKfaBrand, IReqKfaBrandUpdate>({
      query: (data) => ({
        url: `${prefix}/${data.id}`,
        method: 'PUT',
        data,
      }),
    }),
  }),
});

kfaBrandApi.enhanceEndpoints({
  addTagTypes: ['KfaBrand'],
  endpoints: {
    getKfaBrands: {
      providesTags: (result) =>
        result
          ? [...result.content.map(({ id }) => ({ type: 'KfaBrand' as const, id })), 'KfaBrand']
          : ['KfaBrand'],
    },
    createKfaBrand: {
      invalidatesTags: ['KfaBrand'],
    },
    updateKfaBrand: {
      invalidatesTags: ['KfaBrand'],
    },
  },
});

export const {
  useGetKfaBrandsQuery,
  useGetKfaBrandByIdQuery,
  useCreateKfaBrandMutation,
  useUpdateKfaBrandMutation,
} = kfaBrandApi;
