import { IPagination, IPaginationPage, IReqGlobalRsql } from 'src/@types/base';
import { normalizeParams } from 'src/utils/convertFilterString';
import { IHealthcareProvider } from 'src/@types/healthcare-provider';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/kresna/api/kemedis/idash/provider-reports';

const healthcareReportApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: ['healthcareReport'],
});

const _apiWithTaggedEndpoint = healthcareReportApi.injectEndpoints({
  endpoints: (build) => ({
    getHealthcareReport: build.query<IPaginationPage<IHealthcareProvider>, Partial<IReqGlobalRsql>>(
      {
        query: (reqRsql) => ({
          url: `${prefix}`,
          method: 'GET',
          params: normalizeParams(reqRsql),
        }),
      }
    ),
  }),
});

export const { useGetHealthcareReportQuery } = _apiWithTaggedEndpoint;
