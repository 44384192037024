// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  app: path(ROOTS_DASHBOARD, '/app'),

  // providers
  providers: {
    root: path(ROOTS_DASHBOARD, '/providers'),
  },

  // users
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
  },

  // master
  master: {
    root: path(ROOTS_DASHBOARD, '/master'),
    // organization
    organization: {
      root: path(ROOTS_DASHBOARD, '/master/organization'),
      create: path(ROOTS_DASHBOARD, '/master/organization/create'),
      update: (id: string) => path(ROOTS_DASHBOARD, `/master/organization/${id}/update`),
    },
    // end organization

    // location
    location: {
      root: path(ROOTS_DASHBOARD, '/master/location'),
      create: path(ROOTS_DASHBOARD, '/master/location/create'),
      update: (id: string) => path(ROOTS_DASHBOARD, `/master/location/${id}/update`),
    },
    // end location

    // practitioner
    practitioner: {
      root: path(ROOTS_DASHBOARD, '/master/practitioner'),
      create: path(ROOTS_DASHBOARD, '/master/practitioner/create'),
      update: (id: string) => path(ROOTS_DASHBOARD, `/master/practitioner/${id}/update`),
      createSchedule: (id: string) =>
        path(ROOTS_DASHBOARD, `/master/practitioner/${id}/create-schedule`),
    },
    // end practitioner

    // pharmacy
    pharmacy: {
      root: path(ROOTS_DASHBOARD, '/master/pharmacy'),

      // medicine input
      medicineInput: {
        root: path(ROOTS_DASHBOARD, '/master/pharmacy/medicine-input'),
        create: path(ROOTS_DASHBOARD, '/master/pharmacy/medicine-input/create'),
        update: (id: string) =>
          path(ROOTS_DASHBOARD, `/master/pharmacy/medicine-input/${id}/update`),
        createMedicine: path(ROOTS_DASHBOARD, '/master/pharmacy/medicine-input/create-medicine'),
        updateMedicine: (id: string) =>
          path(ROOTS_DASHBOARD, `/master/pharmacy/medicine-input/${id}/update-medicine`),
        createMedicalDevice: path(
          ROOTS_DASHBOARD,
          '/master/pharmacy/medicine-input/create-medical-device'
        ),
        updateMedicalDevice: (id: string) =>
          path(ROOTS_DASHBOARD, `/master/pharmacy/medicine-input/${id}/update-medical-device`),
      },
      // end medicine input
    },
    // end pharmacy

    // product and service
    productAndService: {
      root: path(ROOTS_DASHBOARD, '/master/product-and-service'),

      // product
      product: {
        root: path(ROOTS_DASHBOARD, '/master/product-and-service/product'),
        create: path(ROOTS_DASHBOARD, '/master/product-and-service/product/create'),
        update: (id: string) =>
          path(ROOTS_DASHBOARD, `/master/product-and-service/product/${id}/update`),
      },
      // end product
    },
    // end product and service

    // guarantor
    guarantor: {
      root: path(ROOTS_DASHBOARD, '/master/guarantor'),
      create: path(ROOTS_DASHBOARD, '/master/guarantor/create'),
      update: (id: string) => path(ROOTS_DASHBOARD, `/master/guarantor/${id}/update`),
    },
    // end guarantor
  },
  // end master

  one: path(ROOTS_DASHBOARD, '/one'),
  two: path(ROOTS_DASHBOARD, '/two'),
  three: path(ROOTS_DASHBOARD, '/three'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    four: path(ROOTS_DASHBOARD, '/user/four'),
    five: path(ROOTS_DASHBOARD, '/user/five'),
    six: path(ROOTS_DASHBOARD, '/user/six'),
  },
};
