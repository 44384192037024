import { IMedicine, IPostMedicine, IPrepareMedicine } from 'src/@types/medicine';
import { IPagination, IReqGlobalRsql } from 'src/@types/base';
import { getProviderId } from 'src/utils/local-storage';
import { normalizeParams } from 'src/utils/convertFilterString';
import { baseKemedisApi } from './base-kemedis.api';

const prefix = '/services/arjuna/api/kemedis/medicines';

export type IMedicineOvr = IMedicine & {
  qtyOnCount: number;
  qtyAdjust: number;
};

const medicineApi = baseKemedisApi.enhanceEndpoints({
  addTagTypes: ['Medicine'],
});

const _apiWithTaggedEndpoint = medicineApi.injectEndpoints({
  endpoints: (build) => ({
    getMedicines: build.query<
      IPagination<IMedicineOvr>,
      Partial<IReqGlobalRsql> & { providerId: string }
    >({
      query: (reqRsql) => ({
        url: `${prefix}/providers/${reqRsql.providerId}`,
        method: 'GET',
        params: normalizeParams(reqRsql),
      }),
      providesTags: ['Medicine'],
    }),
    getPrepareMedicine: build.query<IPrepareMedicine, unknown>({
      query: () => ({
        url: `${prefix}/prepare/edit/${getProviderId()}`,
        method: 'GET',
      }),
      providesTags: ['Medicine'],
    }),
    getMedicineById: build.query<IMedicine, string>({
      query: (id) => ({
        url: `${prefix}/${id}`,
        method: 'GET',
      }),
      providesTags: [
        {
          type: 'Medicine',
          id: 'ById',
        },
      ],
    }),
    createMedicine: build.mutation<IMedicine, IPostMedicine>({
      query: (data) => ({
        url: `${prefix}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Medicine'],
    }),
    updateMedicine: build.mutation<IMedicine, IPostMedicine>({
      query: (data) => ({
        url: `${prefix}/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['Medicine'],
    }),
  }),
});

export const {
  useGetMedicinesQuery,
  useGetPrepareMedicineQuery,
  useGetMedicineByIdQuery,
  useCreateMedicineMutation,
  useUpdateMedicineMutation,
} = _apiWithTaggedEndpoint;
