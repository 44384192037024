import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
export const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
export const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
export const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
export const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
export const PageSix = Loadable(lazy(() => import('../pages/PageSix')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

export const DashboardPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')));

// providers
export const ProviderListPage = Loadable(lazy(() => import('../pages/providers/ProviderListPage')));
// end providers

// users
export const UserListPage = Loadable(lazy(() => import('../pages/user/UserListPage')));
// end users

// master
export const MasterLocationPage = Loadable(
  lazy(() => import('../pages/master/Location/MasterIhsLocation'))
);
export const MasterPractitionerPage = Loadable(
  lazy(() => import('../pages/master/Practitioner/MasterIhsPractitioner'))
);
export const MasterPharmacyPage = Loadable(
  lazy(() => import('../pages/master/Pharmacy/MedicineInput/MasterPharmacyMedicineInput'))
);
export const MasterProductServicePage = Loadable(
  lazy(() => import('../pages/master/ProductService/Product/MasterProductServiceProduct'))
);
export const MasterGuarantorPage = Loadable(
  lazy(() => import('../pages/master/Guarantor/MasterGuarantor'))
);
// end master
